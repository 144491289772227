// extracted by mini-css-extract-plugin
export const root = "PlasmicHomepage-module--root--1ZUKo";
export const box__rJ7Wi = "PlasmicHomepage-module--box__rJ7Wi--1XrDK";
export const frame1 = "PlasmicHomepage-module--frame1--2pWRZ";
export const box__hqBFp = "PlasmicHomepage-module--box__hqBFp--BA5hr";
export const frame2 = "PlasmicHomepage-module--frame2--LWpWj";
export const box__lly0Q = "PlasmicHomepage-module--box__lly0Q--FtqyD";
export const eye = "PlasmicHomepage-module--eye--1TQyA";
export const box__kYzSy = "PlasmicHomepage-module--box__kYzSy--2HU6Q";
export const box__d0Twp = "PlasmicHomepage-module--box__d0Twp--3aZrn";
export const frame3 = "PlasmicHomepage-module--frame3--1JqBB";
export const box__g3F3S = "PlasmicHomepage-module--box__g3F3S--1ji9z";
export const people = "PlasmicHomepage-module--people--1HVt-";
export const box__xroNi = "PlasmicHomepage-module--box__xroNi--1oqw-";
export const box___7E6 = "PlasmicHomepage-module--box___7E6--1brlB";
export const frame8 = "PlasmicHomepage-module--frame8--3jaDD";
export const frame4 = "PlasmicHomepage-module--frame4--1mXvY";
export const box__nVr42 = "PlasmicHomepage-module--box__nVr42--1zhkk";
export const heart = "PlasmicHomepage-module--heart---Hlwf";
export const box__lMapD = "PlasmicHomepage-module--box__lMapD--3HMn7";
export const box__c3Kmy = "PlasmicHomepage-module--box__c3Kmy--35jBw";
export const frame9 = "PlasmicHomepage-module--frame9--3kR64";
export const frame5 = "PlasmicHomepage-module--frame5--3MuGM";
export const box__w9YY0 = "PlasmicHomepage-module--box__w9YY0--1_MJD";
export const sugarCube = "PlasmicHomepage-module--sugarCube--1ocCi";
export const box__trBxk = "PlasmicHomepage-module--box__trBxk--2fms_";
export const box__sluxx = "PlasmicHomepage-module--box__sluxx--JbwEY";
export const box__uIhRz = "PlasmicHomepage-module--box__uIhRz--1uliS";
export const frame10 = "PlasmicHomepage-module--frame10--1jEUI";
export const frame6 = "PlasmicHomepage-module--frame6--3_LxF";
export const box__x81KB = "PlasmicHomepage-module--box__x81KB--SFC_1";
export const accuracy = "PlasmicHomepage-module--accuracy--3TFi-";
export const box__lbBlZ = "PlasmicHomepage-module--box__lbBlZ--2qSrU";
export const howIsTheProductGoingToBenefitTheCompany = "PlasmicHomepage-module--howIsTheProductGoingToBenefitTheCompany--296k2";
export const frame11 = "PlasmicHomepage-module--frame11--1qruY";