// extracted by mini-css-extract-plugin
export const all = "plasmic__default_style-module--all--NwouX";
export const img = "plasmic__default_style-module--img--d4eRd plasmic__default_style-module--all--NwouX";
export const input = "plasmic__default_style-module--input--3z4EB plasmic__default_style-module--all--NwouX";
export const textarea = "plasmic__default_style-module--textarea--2n2Bv plasmic__default_style-module--all--NwouX";
export const button = "plasmic__default_style-module--button--2OAT1 plasmic__default_style-module--all--NwouX";
export const code = "plasmic__default_style-module--code--qrmDz plasmic__default_style-module--all--NwouX";
export const pre = "plasmic__default_style-module--pre--1HHC9 plasmic__default_style-module--all--NwouX";
export const h1 = "plasmic__default_style-module--h1--qml5W plasmic__default_style-module--all--NwouX";
export const h2 = "plasmic__default_style-module--h2--3nLoH plasmic__default_style-module--all--NwouX";
export const h3 = "plasmic__default_style-module--h3--2ayUN plasmic__default_style-module--all--NwouX";
export const h4 = "plasmic__default_style-module--h4--1KNLF plasmic__default_style-module--all--NwouX";
export const h5 = "plasmic__default_style-module--h5--35CvV plasmic__default_style-module--all--NwouX";
export const h6 = "plasmic__default_style-module--h6--42pkB plasmic__default_style-module--all--NwouX";
export const address = "plasmic__default_style-module--address--3PB8K plasmic__default_style-module--all--NwouX";
export const ol = "plasmic__default_style-module--ol--2CtmU plasmic__default_style-module--all--NwouX";
export const ul = "plasmic__default_style-module--ul--3gvW3 plasmic__default_style-module--all--NwouX";
export const select = "plasmic__default_style-module--select--OgoL- plasmic__default_style-module--all--NwouX";
export const li = "plasmic__default_style-module--li--mJJH6 plasmic__default_style-module--all--NwouX";
export const plasmic_default__component_wrapper = "plasmic__default_style-module--plasmic_default__component_wrapper--37IPe";
export const __wab_text = "plasmic__default_style-module--__wab_text--2gtvC";
export const plasmic_page_wrapper = "plasmic__default_style-module--plasmic_page_wrapper--3NE1W";